import { useEffect, useRef } from "react"
import { Link, useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom"

import moment from "moment/moment"
// import Delete from "pages/audit-log/delete"
import Filter from "pages/audit-log/filter"
import nProgress from "nprogress"
import { useAuth } from "contexts/auth-context"
import { Pagination } from "components"
import { lang } from "config"
import { Pencil, Plus } from "components/icons/outline"
import { usePage } from "contexts/page-context"
import useAuditLogRepositories from "repositories/audit-log"

export default function List() {
    // Logged in user ability
    const { can, isSuper, currentBranch } = useAuth()
    // Configuration
    const { configuration } = usePage()

    // Transition
    const { title, transition, setTransition } = useOutletContext()

    // React router navigate hook
    const navigate = useNavigate()

    // React router location hook
    const location = useLocation()

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const searchInput = useRef()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const event = searchParams.get('event')
    const actor = searchParams.get('actor')
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    const { data: listData, isLoading: isLoadingListData, mutate: mutateListData } = useAuditLogRepositories({
        page,
        search,
        event,
        actor,
        from,
        to
    })

    useEffect(() => {
        searchInput.current.focus()
    }, [search])

    useEffect(() => {
        window.history.replaceState({}, document.title)

        if (transition.reverse) {
            setTransition({ type: 'slide', reverse: false })
        }
    }, [])

    // Watch changes on petty cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        if (isLoadingListData) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [listData, isLoadingListData])

    // Data filter handlers
    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const removeFilter = () => {
        setSearchParams({})
    }

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between text-xs">
                <div className="flex items-center sm:space-x-2">
                    <div className="relative hidden sm:block">
                        <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx={10} cy={10} r={7}></circle>
                                <line x1={21} y1={21} x2={15} y2={15}></line>
                            </svg>
                        </div>
                        <input ref={searchInput} onChange={(e) => setSearchParams({ search: e.target.value })} value={searchParams.get('search')} type="text" placeholder="Search Audit Log" autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                    </div>
                    <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ event, actor, from, to }).filter(([_, v]) => v != null))} />
                </div>
            </div>
            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                    <thead className="bg-neutral-50 rounded-t-3xl">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Timestamp</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Event</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Actor</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">IP Address</th>
                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-neutral-200">
                        {/* When loading */}
                        {isLoadingListData && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.loading_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available */}
                        {listData?.data.length === 0 && !search && !isLoadingListData && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available on searching */}
                        {listData?.data.length === 0 && search && !isLoadingListData && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_result}
                                </td>
                            </tr>
                        )}

                        {listData?.data.length > 0 && listData.data.map((row) => (
                            <tr key={row.id}>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {moment(row.created_at).format('DD-MM-YYYY')} {moment(row.created_at).format('HH:mm:ss')}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.event}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.actor}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.ip_address}
                                </td>
                                <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                    <div class="inline-flex items-center space-x-2">
                                        <Link to={`/audit-log/${row.id}`} state={{ back: title, from: location?.pathname, transition: 'slide' }} className="inline-flex items-center p-1 text-white transition rounded-full bg-neutral-800 active:hover:scale-90">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <circle cx={12} cy={12} r={2}></circle>
                                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                            </svg>
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination links={listData?.links} from={listData?.from} to={listData?.to} total={listData?.total} />
        </div>
    )
}