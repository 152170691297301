import { useEffect, useLayoutEffect, useState } from "react"
import { Link, useLoaderData, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom"
import { motion } from "framer-motion"

import Divider from "components/divider"

import axios from 'axios'
import moment from "moment/moment"
import { lang } from "config"
import { usePage } from "contexts/page-context"

import * as _ from "lodash"

export default function Detail() {
    // const { user, isSuper } = useAuth()
    const data = useLoaderData()

    const { setTitle } = useOutletContext()

    // const { queueAuditLog } = useAuditLogContext()

    const { setTransition } = usePage()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        setTransition(location.state?.transition)

        getPrevRouteState()

        setTitle(moment(data.created_at).format('DD-MM-YYYY') + " " + moment(data.created_at).format('HH:mm:ss') + " Log")
    }, [])

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between p-6 border border-neutral-200 rounded-xl">
                <div className="flex space-x-2">
                    <div>
                        {/* <CalendarIcon className="w-4 h-4 text-neutral-500" stroke={1.5} /> */}
                    </div>
                    <div>
                        <div className="text-xs text-neutral-500">
                            Timestamp
                        </div>
                        <div className="text-2xl">
                            {moment(data?.created_at).format('MMMM DD, YYYY')}
                        </div>
                    </div>
                </div>

                <div className="flex space-x-2">
                    <div>
                        {/* <CalendarIcon className="w-4 h-4 text-neutral-500" stroke={1.5} /> */}
                    </div>
                    <div>
                        <div className="text-xs text-neutral-500">
                            Event
                        </div>
                        <div className="text-2xl">
                            {data?.event}
                        </div>
                    </div>
                </div>

                <div className="flex space-x-2">
                    <div>
                        {/* <CalendarIcon className="w-4 h-4 text-neutral-500" stroke={1.5} /> */}
                    </div>
                    <div>
                        <div className="text-xs text-neutral-500">
                            Actor
                        </div>
                        <div className="text-2xl">
                            {data?.actor}
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-4 space-y-4">
                    {data?.metadata['new'] ? (
                        <div className={`${false ? 'border-red-200' : 'border-neutral-200'} overflow-x-auto border rounded-xl`}>
                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                <thead className="bg-neutral-50 rounded-t-3xl">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Column</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Before</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">After</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-neutral-200">
                                    {Object.keys(Object.values(data?.metadata ?? [])[0] ?? []).map((row) => (
                                        (Array.isArray(data?.metadata['old'][row]) || Array.isArray(data?.metadata['new'][row])) ? (
                                            <>
                                                <tr>
                                                    <td colSpan={3} className="px-6 py-1 text-xs font-medium uppercase text-neutral-500 bg-neutral-50 whitespace-nowrap">
                                                        {row.replace(/_/g, ' ')}
                                                    </td>
                                                </tr>
                                                {/* {data?.metadata['new'][row].map((item, index) => (
                                                    <>
                                                        <tr>
                                                            <td colSpan={3} className="py-1 pl-12 pr-6 text-xs font-medium uppercase text-neutral-500 bg-neutral-50 whitespace-nowrap">
                                                                No. {index + 1}
                                                            </td>
                                                        </tr>
                                                        {Object.keys(item).map((value) => (
                                                            <tr>
                                                                <td className="py-4 pl-16 pr-6 text-xs capitalize text-neutral-500 whitespace-nowrap">
                                                                    {value.replace(/_/g, ' ')}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {data?.metadata['old'][row][index] ? data?.metadata['old'][row][index][value] === Object(data?.metadata['old'][row][index][value]) ? data?.metadata['old'][row][index][value]?.name : data?.metadata['old'][row][index][value] : '-'}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {data?.metadata['new'][row][index][value] === Object(data?.metadata['new'][row][index][value]) ? data?.metadata['new'][row][index][value]?.name : data?.metadata['new'][row][index][value]}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ))} */}
                                                {_.merge([], data?.metadata['old'][row], data?.metadata['new'][row]).map((item, index) => (
                                                    <>
                                                        <tr>
                                                            <td colSpan={3} className="py-1 pl-12 pr-6 text-xs font-medium uppercase text-neutral-500 bg-neutral-50 whitespace-nowrap">
                                                                No. {index + 1}
                                                            </td>
                                                        </tr>
                                                        {Object.keys(item).map((value) => (
                                                            <tr>
                                                                <td className="py-4 pl-16 pr-6 text-xs capitalize text-neutral-500 whitespace-nowrap">
                                                                    {value.replace(/_/g, ' ')}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {data?.metadata['old'][row][index] ? data?.metadata['old'][row][index][value] === Object(data?.metadata['old'][row][index][value]) ? data?.metadata['old'][row][index][value]?.name : data?.metadata['old'][row][index][value] : '-'}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {data?.metadata['new'][row][index] ? data?.metadata['new'][row][index][value] === Object(data?.metadata['new'][row][index][value]) ? data?.metadata['new'][row][index][value]?.name : data?.metadata['new'][row][index][value] : '-'}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ))}
                                            </>
                                        ) : (
                                            <tr>
                                                <td className="px-6 py-4 text-xs capitalize text-neutral-500 whitespace-nowrap">
                                                    {row.replace(/_/g, ' ')}
                                                </td>
                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {data?.metadata['old'][row]}
                                                </td>
                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {data?.metadata['new'][row]}
                                                </td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className={`${false ? 'border-red-200' : 'border-neutral-200'} overflow-x-auto border rounded-xl`}>
                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                <thead className="bg-neutral-50 rounded-t-3xl">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Column</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Value</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-neutral-200">
                                    {Object.keys(data?.metadata ?? []).map((row) => (
                                        Array.isArray(data?.metadata[row]) ? (
                                            <>
                                                <tr>
                                                    <td colSpan={2} className="px-6 py-1 text-xs font-medium uppercase text-neutral-500 bg-neutral-50 whitespace-nowrap">
                                                        {row.replace(/_/g, ' ')}
                                                    </td>
                                                </tr>
                                                {data?.metadata[row].map((item, index) => (
                                                    <>
                                                        <tr>
                                                            <td colSpan={2} className="py-1 pl-12 pr-6 text-xs font-medium uppercase text-neutral-500 bg-neutral-50 whitespace-nowrap">
                                                                No. {index + 1}
                                                            </td>
                                                        </tr>
                                                        {item === Object(item) ? Object.keys(item).map((value) => (
                                                            <tr>
                                                                <td className="py-4 pl-16 pr-6 text-xs capitalize text-neutral-500 whitespace-nowrap">
                                                                    {value.replace(/_/g, ' ')}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {item[value] === Object(item[value]) ? item[value]?.name : item[value]}
                                                                </td>
                                                            </tr>
                                                        )) : (
                                                            <tr>
                                                                <td className="py-4 pl-16 pr-6 text-xs capitalize text-neutral-500 whitespace-nowrap">
                                                                    {null}
                                                                </td>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {item}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                ))}
                                            </>
                                        ) : (
                                            <tr>
                                                <td className="px-6 py-4 text-xs capitalize text-neutral-500 whitespace-nowrap">
                                                    {row.replace(/_/g, ' ')}
                                                </td>
                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {data?.metadata[row]}
                                                </td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                {/* {JSON.stringify(data?.metadata)} */}

                <div className="col-span-2 p-6 space-y-6 text-xs border rounded-xl border-neutral-200 h-fit">
                    {/* Amount */}
                    <Divider content="Detail" className="font-medium" />
                    {/* <div className="flex space-x-2">
                        <div className="space-y-2">
                            <div className="font-medium">URL</div>
                            <div>{data?.url}</div>
                        </div>
                    </div> */}
                    {/* <div className="flex space-x-2">
                        <div className="space-y-2">
                            <div className="font-medium">Method</div>
                            <div>{data?.method}</div>
                        </div>
                    </div> */}
                    <div className="flex space-x-2">
                        {/* <div> */}
                        {/* <ArrowBackUpIcon className="w-4 h-4" stroke={1.5} /> */}
                        {/* </div> */}
                        <div className="space-y-2">
                            <div className="font-medium">IP Address</div>
                            <div>{data?.ip_address}</div>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        {/* <div> */}
                        {/* <ArrowBackUpIcon className="w-4 h-4" stroke={1.5} /> */}
                        {/* </div> */}
                        <div className="space-y-2">
                            <div className="font-medium">User Agent</div>
                            <div>{`${data?.user_agent.platform ?? "Unknown Platform"}`} - {`${data?.user_agent.browser ?? "Unknown Browser"}`}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}