import { createBrowserRouter, createHashRouter } from "react-router-dom"

import GuestRoute from "routes/middleware/guest"
import ProtectedRoute from "routes/middleware/protected"

import Dashboard from "pages/dashboard"
import Login from "pages/auth/login"
import Register from "pages/auth/register"
import NoRole from "pages/no-role"
import { show as showDashboard } from "./loader/dashboard"
import { load as loadMainData } from "./loader/main"
import { show as showAttribute } from "./loader/attribute"
import { show as showAuditLog } from "./loader/audit-log"

import { getByUser as getBranchByUser } from "./loader/branch"
import SelectBranch from "pages/select-branch"
import { lang } from "config"
import Error from "pages/error"
import { List as AttributeList, Create as CreateAttribute, Edit as EditAttribute } from "pages/attribute"
import { List as AuditLogList, Detail as AuditLogDetail } from "pages/audit-log"

import AppLayout from "layouts/app-layout"

export const routeList = [
    // Root loader
    {
        loader: loadMainData,
        errorElement: <Error />,
        children: [
            // Guest
            {
                path: "/register",
                element: (
                    <GuestRoute>
                        <Register title={lang.title.sign_up} />
                    </GuestRoute>
                )
            },
            {
                path: "/login",
                element: (
                    <GuestRoute>
                        <Login title={lang.title.sign_in} />
                    </GuestRoute>
                )
            },

            // Protected
            {
                path: "/branch/select",
                element: (
                    <ProtectedRoute>
                        <SelectBranch title={lang.title.select_branch} />
                    </ProtectedRoute>
                ),
                loader: getBranchByUser
            },
            {
                path: "/no-role",
                element: (
                    <ProtectedRoute>
                        <NoRole title="No Role Assigned" />
                    </ProtectedRoute>
                )
            },

            // Protected, App Layout
            {
                middleware: "protected",
                element: (
                    <ProtectedRoute>
                        <AppLayout />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        path: "/",
                        index: true,
                        title: "Dashboard",
                        element: (
                            <Dashboard />
                        ),
                        // loader: showDashboard
                    },
                    {
                        path: "/audit-log",
                        title: "Audit Log",
                        element: (
                            <AuditLogList key="/audit-log" />
                        )
                    },
                    {
                        path: "/audit-log/:id",
                        element: (
                            <AuditLogDetail />
                        ),
                        loader: showAuditLog
                    },
                    {
                        path: "/attribute",
                        title: "Attribute",
                        element: (
                            <AttributeList key="/attribute" />
                        )
                    },
                    {
                        path: "/attribute/create",
                        title: "Create Attribute",
                        element: (
                            <CreateAttribute key="/attribute/create" />
                        )
                    },
                    {
                        path: "/attribute/:id/edit",
                        element: (
                            <EditAttribute />
                        ),
                        loader: showAttribute
                    }
                ]
            }
        ]
    }
]

export const routes = process.env.NODE_ENV === 'development' ? createBrowserRouter(routeList) : createHashRouter(routeList)

export default routes